import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Query } from '../graphql-types';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import { Container, Row, Col } from 'reactstrap';
import TitleBox from '../components/title-box';
import SEO from '../components/seo';

const AreaOfLawPage: React.FC<PageProps<Pick<Query, 'strapiAreaOfLaw'>>> = ({
  data,
}) => {
  const {
    strapiAreaOfLaw: { title, content },
  } = data;
  return (
    <Layout>
      <SEO title={title} />

      <main>
        <TitleBox>
          <h1>{title}</h1>
        </TitleBox>
        <Container className="pt-5">
          <Row>
            <Col>
              <ReactMarkdown source={content} />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default AreaOfLawPage;

export const query = graphql`
  query AreaOfLawBySlug($slug: String!) {
    strapiAreaOfLaw(slug: { eq: $slug }) {
      title
      content
    }
  }
`;
